import Image, { ImageProps } from 'next/image'

import styles from './vehicle-image.module.scss'
import { getGalleryImage, getVehicleHeroImage } from '@/lib/utilities/vehicle'

export type VehicleImageProps = {
  alt: string
  height: number
  width: number
  imagePath: string
  backupImagePath: string
  priority?: boolean
  quality?: number
  layout?: 'fill' | 'fixed' | 'intrinsic' | 'responsive' | undefined
  placeholder?: ImageProps['placeholder']
  blurDataURL?: string
  className?: string
  type?: 'DEFAULT' | 'GALLERY' | 'BROWSE' | 'HOMEPAGE' | 'SPEC'
  unoptimized?: boolean
}

export const VehicleImage = ({
  alt,
  height,
  width,
  imagePath,
  backupImagePath,
  priority = false,
  quality = 75,
  layout = undefined,
  placeholder = 'empty',
  blurDataURL,
  className = '',
  type = 'DEFAULT',
  unoptimized = false,
}: VehicleImageProps) => {
  return (
    <div
      className={`
        ${
          type !== 'GALLERY' && type !== 'BROWSE' && type !== 'HOMEPAGE' && imagePath
            ? styles['reverse-image']
            : ''
        } ${!imagePath ? styles['padded-image'] : ''}`}>
      <Image
        src={imagePath ? getGalleryImage(imagePath) : getVehicleHeroImage(backupImagePath)}
        alt={alt}
        height={height}
        width={width}
        priority={priority}
        quality={quality}
        layout={layout}
        placeholder={placeholder}
        blurDataURL={blurDataURL}
        className={className}
        unoptimized={unoptimized}
      />
    </div>
  )
}
