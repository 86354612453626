import Image, { ImageProps } from 'next/image'
import { useRef, useState } from 'react'
import { Fade } from 'react-awesome-reveal'

import styles from './vehicle-image.module.scss'
import { Button, ButtonHierarchy } from '@/components/styleguide/Button'
import { getGalleryImage } from '@/lib/utilities/vehicle'
import { Size, Theme } from '@/types/system'
import { VehicleGalleryItem } from '@/types/vehicle'

export type VehicleImageWithGalleryProps = {
  alt: string
  height: number
  width: number
  images: VehicleGalleryItem[]
  quality?: number
  layout?: 'fill' | 'fixed' | 'intrinsic' | 'responsive' | undefined
  placeholder?: ImageProps['placeholder']
  blurDataURL?: string
  className?: string
  type: 'DEFAULT' | 'GALLERY' | 'BROWSE' | 'HOMEPAGE' | 'SPEC'
  unoptimized?: boolean
  showDots?: boolean
  alignDots?: 'left' | 'center' | 'right'
  onChangeIndex?: (index: number) => void
}

export const VehicleImageWithGallery = ({
  alt,
  height,
  width,
  images,
  quality = 75,
  layout = undefined,
  placeholder = undefined,
  blurDataURL,
  className = '',
  unoptimized = false,
  type = 'DEFAULT',
  showDots = true,
  alignDots = 'center',
  onChangeIndex,
}: VehicleImageWithGalleryProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const ref = useRef<HTMLDivElement>(null)

  const onChangeCurrentIndex = (index: number) => {
    if (onChangeIndex) {
      onChangeIndex(index)
    }

    setCurrentIndex(index)
  }

  return (
    <div className={styles.wrapper} data-type={type}>
      <div
        ref={ref}
        className={styles.carousel}
        onScroll={e => {
          const target = e.target as HTMLDivElement
          const calculation = Math.floor(target.scrollLeft / target.children[0].clientWidth)
          onChangeCurrentIndex(calculation < 0 ? 0 : calculation)
        }}>
        {images
          .filter(image => image.angleType === 'EXTERIOR')
          .sort(a => (a.angle === 'lowaggressive' ? -1 : 1))
          .map((image, index) => (
            <Fade
              triggerOnce
              duration={1000}
              className={styles.item}
              key={image.id}>
              <div
                data-index={index}
                className={`${styles.content} ${
                  type !== 'GALLERY' && type !== 'BROWSE' && type !== 'HOMEPAGE' && image.path
                    ? styles['reverse-image']
                    : ''
                } ${type === 'SPEC' ? styles['spec-image'] : ''}`}>
                <Image
                  src={getGalleryImage(image.path)}
                  alt={alt}
                  height={height}
                  width={width}
                  quality={quality}
                  layout={layout}
                  placeholder={placeholder}
                  blurDataURL={blurDataURL}
                  className={className}
                  unoptimized={unoptimized}
                />
              </div>
            </Fade>
          ))}
      </div>
      {showDots &&
        <ul className={styles.dots} data-alignment={alignDots}>
          {images
            .filter(image => image.angleType === 'EXTERIOR')
            .sort(a => (a.angle === 'lowaggressive' ? -1 : 1))
            .map((image, index) => (
              <li
                key={image.id}
                className={`${styles.dot}${currentIndex === index ? ' ' + styles.active : ''}${Math.abs(currentIndex - index) > 2 ? (Math.abs(currentIndex - index) >= 4 ? ' ' + styles['too-far'] : ' ' + styles.far) : Math.abs(currentIndex - index) > 1 ? ' ' + styles.close : ''}`}></li>
            ))}
        </ul>
      }
      <div
        className={styles.arrows}
        data-navigation-position={
          currentIndex === 0
            ? 'start'
            : currentIndex ===
                images
                  .filter(image => image.angleType === 'EXTERIOR')
                  .sort(a => (a.angle === 'lowaggressive' ? -1 : 1)).length -
                  1
              ? 'end'
              : 'middle'
        }>
        <Button
          onClick={() => {
            ref?.current?.scrollTo({
              left: ref.current.scrollLeft - ref?.current?.children[0]?.clientWidth,
              behavior: 'smooth',
            })
          }}
          icon="ChevronLeft"
          size={Size.ExtraSmall}
          hierarchy={type === 'SPEC' ? ButtonHierarchy.BezeledGray : ButtonHierarchy.Filled}
          theme={type === 'SPEC' ? Theme.light : Theme.dark}
        />
        <Button
          onClick={() => {
            ref?.current?.scrollTo({
              left: ref.current.scrollLeft + ref?.current?.children[0]?.clientWidth,
              behavior: 'smooth',
            })
          }}
          theme={type === 'SPEC' ? Theme.light : Theme.dark}
          icon="ChevronRight"
          size={Size.ExtraSmall}
          hierarchy={type === 'SPEC' ? ButtonHierarchy.BezeledGray : ButtonHierarchy.Filled}
        />
      </div>
    </div>
  )
}
