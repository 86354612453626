import { VehicleCard } from '@/components/styleguide/Card/Vehicle/VehicleCard'
import { Carousel } from '@/components/styleguide/Carousel'
import { SearchResult } from '@/types/search'

export const DEALS_UNDER_300 = 'under-350'
export const NO_DEPOSIT_DEALS = 'no-deposit'
export const ALL_DEALS = 'all'

export type TopDealsProps = {
  isVans: boolean
  type?: typeof DEALS_UNDER_300 | typeof ALL_DEALS | typeof NO_DEPOSIT_DEALS
  dealList: SearchResult[]
}

export const TopDeals = ({
  isVans,
  dealList,
  type = ALL_DEALS,
}: TopDealsProps) => {

  if (!dealList) {
    return false
  }

  return (
    <Carousel
      header={
        <>
          <p>Picked by Luis</p>
          <h2>
            {type === NO_DEPOSIT_DEALS
              ? `No desposit ${isVans ? 'van' : 'car'} leasing`
              : type === DEALS_UNDER_300
                ? `${isVans ? 'Van' : 'Car'} leasing for under £300/pm`
                : `Today's top ${isVans ? 'van' : 'car'} lease deals`}
          </h2>
        </>
      }
      scrollValue={408}
      moreLink={
        type === NO_DEPOSIT_DEALS
          ? `${isVans ? 'van' : 'car'}-leasing/no-deposit`
          : type === DEALS_UNDER_300
            ? `${isVans ? 'van' : 'car'}-leasing/budget/300`
            : `${isVans ? 'van' : 'car'}-leasing/search`
      }
      moreLabel={`${type === DEALS_UNDER_300 ? 'cheap' : type === NO_DEPOSIT_DEALS ? 'no deposit' : 'of the best'} deals`}
      sectionName={`${type === DEALS_UNDER_300 ? 'cheapest' : type === NO_DEPOSIT_DEALS ? 'no-deposit' : 'best'}-deals`}>
      {dealList.map(deal => (
        <VehicleCard
          deal={deal}
          key={deal.vehiclePrice.id}
          type="carousel"
        />
      ))}
    </Carousel>
  )
}
