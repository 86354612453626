import { VehicleCard } from '@/components/styleguide/Card/Vehicle/VehicleCard'
import { Carousel } from '@/components/styleguide/Carousel'
import { SearchResult } from '@/types/search'

export type RecentlyViewedProps = {
  isVans: boolean
  dealList: SearchResult[]
}

export const RecentlyViewed = ({ dealList }: RecentlyViewedProps) => {
  if (!dealList || dealList.length === 0) {
    return false
  }

  return (
    <Carousel
      header={<h2>Recently viewed</h2>}
      scrollValue={408}
      showMoreButton={false}
      sectionName="recently-viewed">
      {dealList.slice(0, 12).map(deal => (
        <VehicleCard
          deal={deal}
          key={deal.vehiclePrice.id}
          type="carousel"
        />
      ))}
    </Carousel>
  )
}
